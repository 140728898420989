










import { store } from '@/store'
import {registerNavbar} from '@/services/scroller';
import Vue from 'vue'
export default Vue.extend({
  mounted() {
    registerNavbar(this.$refs['navbar'] as HTMLElement);
  },
  methods: {
    goToProfile() {
      this.$router.push({name: "profile"});
    }
  },
  computed: {
    loggedIn() {
      return store.user !== undefined;
    }
  }
})
