









import Vue, { PropType } from 'vue'

type Hint = {
    value: number;
    enabled: boolean;
}
export default Vue.extend({
    props: {
        label: {
            type: Number,
            required: false
        },
        hints: Array as PropType<number[]>,
        coordinates: Object as PropType<{row: number, col: number}>,
        fixed: Boolean,
        selected: Boolean,
        wrong: Boolean,
        won: Boolean
    },
    data() {
        return {
            id: (crypto as any).randomUUID()
        };
    },
    computed: {
        visualLabel(): string {
            return (this.label || "&nbsp").toString()
        },
        classes(): string[] {
            const r = [];
            if (this.coordinates.row % 3 === 2 && this.coordinates.row < 8) {
                r.push("strong-bottom");
            }
            if (this.coordinates.col % 3 === 2 && this.coordinates.col < 8) {
                r.push("strong-right");
            }
            if (this.coordinates.row % 3 === 0 && this.coordinates.row > 0) {
                r.push("strong-top");
            }
            if (this.coordinates.col % 3 === 0 && this.coordinates.col > 0) {
                r.push("strong-left");
            }
            return r;
        },
        topHints(): Hint[] {
            const r = [];
            for (let i = 1; i <= 5; i++) {
                r.push({
                    value: i,
                    enabled: this.hints.includes(i)
                })
            }
            return r;
        },
        bottomHints(): Hint[] {
            const r = [];
            for (let i = 6; i <= 9; i++) {
                r.push({
                    value: i,
                    enabled: this.hints.includes(i)
                })
            }
            return r;
        }
    }
})
