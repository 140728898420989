







import Vue from 'vue';
import Navbar from './components/Navbar.vue';
import { store } from './store';

export default Vue.extend({
  components: {
    Navbar
  },
  computed: {
    loading() {
      return store.loading > 0;
    }
  }
})
