















import Vue, { PropType } from "vue"

import {persistence} from "../services/persistence";
import {SudokuSummary, generateSudoku} from "../services/sudoku";
import { formatDate, formatTime } from "@/services/date";

export default Vue.extend({
    data() {
        return {
            summaries: [] as SudokuSummary[]
        }
    },
    mounted() {
        persistence.init().then(() => {
            persistence.getList().then(e => this.summaries = e);
        })
    },
    methods: {
        select(s: SudokuSummary) {
            this.$router.push({name: "play", params: {puzzleId: s.id}} )
        },
        showDate(d: Date) {
            return formatDate(d);
        },
        showTime(d: Date) {
            return formatTime(d);
        },
        async createPuzzle() {
            await persistence.addNew(generateSudoku("easy"));
            this.summaries = await persistence.getList();
        }
    }
})
