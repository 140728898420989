














import { api } from '@/services/api';
import { auth } from '@/services/auth';
import { store } from '@/store';
import Vue from 'vue'
export default Vue.extend({
    data() {
        return {
            user: "",
            password: ""
        }
    },
    methods: {
        async login() {
            await auth.login(this.user, this.password);
        }
    },
    computed: {
        canLogin(): boolean {
            return this.user.length > 0 && this.password.length > 0;
        }
    }
})
